<template>
  <b-card>
    <vx-form
      v-slot="{ loading, can }"
      :resource="formResource"
      @submit="handleSubmit"
    >
      <vx-input
        v-model="formValue.title"
        :rules="rules.title"
        name="title"
        label="Title"
      />

      <vx-input
        v-model="formValue.description"
        :rules="rules.description"
        name="description"
        label="Description"
      />

      <vx-input
        v-model="formValue.prize_type"
        :rules="rules.prize_type"
        name="prize_type"
        label="Prize type"
        disabled
      />

      <vx-select-resource
        v-model="formValue.prize_id"
        :rules="rules.prize_id"
        :resource="moneyBonusesResource"
        :reduce="({ id }) => id"
        options-label="name"
        name="prize_id"
        label="Prize"
      />

      <vx-select
        v-model="formValue.type"
        :options="missionTypes"
        :clearable="false"
        name="type"
        label="Type"
      />

      <vx-date-range-picker
        v-if="missionTypeIsRegular"
        v-model="formValue.generated_at"
        :rules="rules.generated_at"
        :clearable="false"
        :disabled-date="isDayBefore"
        :disabled-time="disabledTime"
        name="generated_at"
        label="Activity datetime range"
        type="datetime"
        show-range-duration
      />

      <vx-date-picker
        v-model="formValue.created_at"
        name="created_at"
        label="Created At"
        type="date"
        disabled
      />

      <vx-checkbox
        v-if="isEdit"
        v-model="formValue.is_enabled"
        name="is_enabled"
        label="Enabled"
      />

      <vx-button
        :loading="loading"
        :can="can"
        variant="primary"
        type="submit"
      >
        Save
      </vx-button>
    </vx-form>
  </b-card>
</template>

<script>
import { computed, reactive } from '@vue/composition-api'
import {
  VxForm,
  VxInput,
  VxCheckbox,
  VxSelectResource,
  VxSelect,
  VxDateRangePicker,
  VxDatePicker
} from '@/components/form'
import { VxButton } from '@/components/buttons'
import { bonuses, missions, missionTypes, passDataToResource } from '@/services/resources'
import router, { buildRoute, path } from '@/router'
import { setValuesToForm, apiDefaultValidators } from '@/services/form'
import { BCard } from 'bootstrap-vue'
import { addSecondsToISODate, isDayBefore } from '@/services/utils'
import { differenceInSeconds, isBefore } from 'date-fns'

export default {
  name: 'MissionEditor',
  components: {
    VxDatePicker,
    VxDateRangePicker,
    BCard,
    VxForm,
    VxInput,
    VxCheckbox,
    VxSelect,
    VxSelectResource,
    VxButton
  },
  props: {
    mission: {
      type: Object,
      default: null
    }
  },
  emits: ['updateMission'],
  setup ({ mission }, { emit }) {
    const isEdit = !!mission
    const moneyBonusesResource = passDataToResource(bonuses.money, {
      requestParams: {
        params: {
          sort_by: '-id',
          per_page: 100
        }
      }
    })

    const frontToBackMapper = ({ generated_at, description, ...rest }) => ({
      ...rest,
      ...(generated_at && {
        generated_at: generated_at[0],
        lifetime: differenceInSeconds(generated_at[1], generated_at[0])
      }),
      ...(description && { description })
    })

    const backToFrontMapper = ({ generated_at, lifetime, ...rest }) => ({
      ...rest,
      ...(generated_at && lifetime && {
        generated_at: [generated_at, addSecondsToISODate(generated_at, lifetime)]
      })
    })

    const formValue = reactive({
      title: '',
      description: '',
      prize_id: null,
      prize_type: 'money_bonus',
      type: missionTypes.regular,
      created_at: null,
      generated_at: [null, null],
      is_enabled: false
    })

    if (isEdit) {
      setValuesToForm(backToFrontMapper(mission), formValue)
    }

    const formResource = isEdit
      ? passDataToResource(missions.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: mission.id } }
      })
      : passDataToResource(missions.create, {
        frontToBackMapper
      })

    const missionTypeIsRegular = computed(() => formValue.type === missionTypes.regular)

    const { maxTinyTextLength } = apiDefaultValidators
    const rules = {
      title: {
        required: true,
        max: maxTinyTextLength
      },
      prize_type: {
        required: true
      },
      prize_id: {
        required: true
      },
      type: {
        required: true
      },
      generated_at: {
        required_range: true,
        min_value_range: {
          value: 1,
          step: 'hours'
        }
      }
    }

    const handleSubmit = ([err, res]) => {
      if (err) return

      const { data } = res
      emit('updateMission', data)

      if (!isEdit) {
        router.push(buildRoute(path.updateMission, { params: { id: data.id } }))
      }
    }

    const disabledTime = (date) => {
      return isBefore(date, new Date())
    }

    return {
      formResource,
      formValue,
      rules,
      isEdit,
      handleSubmit,

      isDayBefore,
      disabledTime,

      moneyBonusesResource,

      missionTypeIsRegular,
      missionTypes: Object.values(missionTypes)
    }
  }
}
</script>
