import { taskReasons } from '@/services/resources'
import { computed, reactive, unref } from '@vue/composition-api'
import TaskDropSumFields from '@/views/missions/task/TaskDropSumFields'
import { isFilled } from '@/services/form'
import { chanceFormat, chanceToInteger, integerToChance } from '@/services/utils'

const useDropSum = (task) => {
  const defaultValues = {
    max_chance: null,
    profit_rate: null
  }

  const formValue = reactive({
    reason: taskReasons.caseOpening,
    max_chance: defaultValues.max_chance,
    profit_rate: defaultValues.profit_rate
  })

  const formDef = {
    component: TaskDropSumFields,
    value: formValue,
    frontToBackMapper: ({ max_chance, profit_rate, ...rest }) => ({
      ...rest,
      ...(isFilled(max_chance) && { max_chance: chanceToInteger(max_chance) }),
      ...(isFilled(profit_rate) && { profit_rate })
    }),
    backToFrontMapper: ({ max_chance, ...rest }) => ({
      ...rest,
      max_chance: integerToChance(max_chance)
    })
  }

  const additionColumns = {
    [taskReasons.any]: [
      {
        key: 'options.max_chance',
        label: 'Max chance'
      },
      {
        key: 'options.profit_rate',
        label: 'Profit rate'
      }
    ],
    [taskReasons.caseOpening]: [
      {
        key: 'options.profit_rate',
        label: 'Profit rate'
      }
    ],
    [taskReasons.upgrade]: [
      {
        key: 'options.max_chance',
        label: 'Max chance',
        formatter: chanceFormat
      }
    ]
  }

  const columnsByReason = computed(() => {
    return additionColumns[unref(task)?.options?.reason] || []
  })

  const columns = computed(() => ([
    {
      key: 'options.reason',
      label: 'Reason'
    },
    ...columnsByReason.value
  ]))

  return {
    formDef,
    columns,
    defaultValues
  }
}

export default useDropSum
