<template>
  <vx-input
    v-model="formValue.url"
    :rules="rules.url"
    name="url"
    label="Url"
  />
</template>

<script>
import { VxInput } from '@/components/form'
import { computed } from '@vue/composition-api'

export default {
  name: 'TaskVisitLinkFields',
  components: { VxInput },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const formValue = computed({
      get: () => props.value,
      set: (newValue) => emit('input', newValue)
    })

    const rules = {
      url: {
        required: true,
        url: true
      }
    }

    return {
      formValue,
      rules
    }
  }
}
</script>
