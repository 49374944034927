<template>
  <div>
    <vx-select
      v-model="formValue.is_win"
      :rules="rules.is_win"
      :options="taskUpgradeIsWinTypes"
      :reduce="({ value }) => value"
      options-label="label"
      name="is_win"
      label="Is win"
    />
    <vx-input
      v-model="formValue.max_chance"
      :rules="rules.max_chance"
      type="number"
      name="max_chance"
      step="any"
      label="Max Chance (%)"
    />
    <vx-input
      v-model="formValue.min_item_price"
      :rules="rules.min_item_price"
      type="number"
      name="min_item_price"
      step="0.01"
      label="Min item price ($)"
    />
  </div>
</template>

<script>
import { VxInput, VxSelect } from '@/components/form'
import { computed } from '@vue/composition-api'
import { taskUpgradeIsWinTypes } from '@/services/resources'

export default {
  name: 'TaskUpgradeFields',
  components: {
    VxSelect,
    VxInput
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const formValue = computed({
      get: () => props.value,
      set: (newValue) => {
        emit('input', newValue)
      }
    })

    const rules = {
      reason: {
        required: true
      },
      max_chance: {
        min_value: 0,
        max_value: 100,
        decimal: 3
      },
      min_item_price: {
        min_value: 0,
        decimal: 2
      }
    }

    return {
      formValue,
      rules,
      taskUpgradeIsWinTypes
    }
  }
}
</script>
