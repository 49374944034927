var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-select',{attrs:{"rules":_vm.rules.reason,"options":_vm.reasonOptions,"name":"reason","label":"Reason"},model:{value:(_vm.formValue.reason),callback:function ($$v) {_vm.$set(_vm.formValue, "reason", $$v)},expression:"formValue.reason"}}),_c('vx-input',{attrs:{"rules":_vm.rules.min_drop_price,"type":"number","name":"min_drop_price","step":"0.01","label":"Min Drop Price ($)"},model:{value:(_vm.formValue.min_drop_price),callback:function ($$v) {_vm.$set(_vm.formValue, "min_drop_price", $$v)},expression:"formValue.min_drop_price"}}),_c('vx-select-resource',{attrs:{"resource":_vm.categoriesBaseInfoResource,"resource-mapper":function (ref) {
	var rarity = ref.rarity;

	return rarity;
},"reduce":function (ref) {
	var short = ref.short;

	return short;
},"options-label":"full","name":"rarity","label":"Rarity"},model:{value:(_vm.formValue.rarity),callback:function ($$v) {_vm.$set(_vm.formValue, "rarity", $$v)},expression:"formValue.rarity"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }