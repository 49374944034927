import { reactive } from '@vue/composition-api/dist/vue-composition-api'
import TaskCaseOpenedFields from '@/views/missions/task/TaskCaseOpenedFields'
import { formatToCents, formatToDecimal } from '@/services/utils'
import { VxCellMoney, VxCellResource } from '@/components/table'
import { isFilled } from '@/services/form'
import { passDataToResource, sections } from '@/services/resources'

const useCaseOpened = () => {
  const formValue = reactive({
    min_case_price: 0,
    profit_rate: 0,
    case_section_id: null
  })

  const formDef = {
    component: TaskCaseOpenedFields,
    value: formValue,
    frontToBackMapper: ({ min_case_price, profit_rate, ...rest }) => ({
      ...rest,
      ...(isFilled(min_case_price) && { min_case_price: formatToCents(min_case_price) }),
      ...(isFilled(profit_rate) && { profit_rate })
    }),
    backToFrontMapper: ({ min_case_price, ...rest }) => ({
      ...rest,
      min_case_price: formatToDecimal(min_case_price)
    })
  }

  const columns = [
    {
      key: 'options.min_case_price',
      label: 'Min Case Price',
      component: VxCellMoney
    },
    {
      key: 'options.profit_rate',
      label: 'Profit Rate'
    },
    {
      key: 'options.case_section_id',
      label: 'Case Section',
      component: VxCellResource,
      tdAttr: (value) => ({
        resource: passDataToResource(sections.getOne, {
          requestParams: { urlParams: { id: value } }
        }),
        responseMapper: (res) => {
          return res?.data?.title
        }
      })
    }
  ]

  return {
    formDef,
    columns
  }
}

export default useCaseOpened
