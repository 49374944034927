<template>
  <div>
    <vx-select
      v-model="formValue.reason"
      :rules="rules.reason"
      :options="reasonOptions"
      name="reason"
      label="Reason"
    />
    <vx-input
      v-if="showAdditionField('max_chance')"
      v-model="formValue.max_chance"
      :rules="rules.max_chance"
      type="number"
      name="max_chance"
      step="any"
      label="Max Chance (%)"
    />
    <vx-input
      v-if="showAdditionField('profit_rate')"
      v-model="formValue.profit_rate"
      :rules="rules.profit_rate"
      type="number"
      name="profit_rate"
      step="0.01"
      label="Profit Rate"
    />
  </div>
</template>

<script>
import { VxInput, VxSelect } from '@/components/form'
import { computed, watch } from '@vue/composition-api'
import { taskReasons } from '@/services/resources'
import useDropSum from '@/views/missions/task/useDropSum'
import { difference } from 'lodash'

export default {
  name: 'TaskDropSumFields',
  components: { VxSelect, VxInput },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const { defaultValues } = useDropSum()

    const formValue = computed({
      get: () => props.value,
      set: (newValue) => {
        emit('input', newValue)
      }
    })

    const additionFieldsByReason = {
      [taskReasons.any]: ['max_chance', 'profit_rate'],
      [taskReasons.upgrade]: ['max_chance'],
      [taskReasons.caseOpening]: ['profit_rate']
    }

    const showAdditionField = (field) => {
      return additionFieldsByReason[formValue.value.reason].includes(field)
    }

    // Set default value to unused fields after change reason
    watch(
      () => props.value.reason,
      (reason) => {
        const fieldsToClear = ['max_chance', 'profit_rate']
        if (reason === taskReasons.any) return

        const newFields = additionFieldsByReason[reason]
        difference(fieldsToClear, newFields).forEach((field) => {
          formValue.value[field] = defaultValues[field]
        })
      }
    )

    const rules = {
      reason: {
        required: true
      },
      max_chance: {
        min_value: 0,
        max_value: 100,
        decimal: 3
      },
      profit_rate: {
        min_value: 0,
        decimal: 2
      }
    }

    return {
      formValue,
      rules,
      reasonOptions: Object.values(taskReasons),
      showAdditionField
    }
  }
}
</script>
