import { taskReasons } from '@/services/resources'
import { reactive } from '@vue/composition-api'
import { formatToCents, formatToDecimal } from '@/services/utils'
import { VxCellMoney } from '@/components/table'
import TaskDropQuantityFields from '@/views/missions/task/TaskDropQuantityFields'
import { isFilled } from '@/services/form'

const useDropQuantity = () => {
  const formValue = reactive({
    reason: taskReasons.caseOpening,
    min_drop_price: null,
    rarity: null
  })

  const formDef = {
    component: TaskDropQuantityFields,
    value: formValue,
    frontToBackMapper: ({ min_drop_price, rarity, ...rest }) => {
      return ({
        ...rest,
        ...(isFilled(min_drop_price) && { min_drop_price: formatToCents(min_drop_price) }),
        ...(isFilled(rarity) && { rarity })
      })
    },
    backToFrontMapper: ({ min_drop_price, ...rest }) => ({
      ...rest,
      min_drop_price: formatToDecimal(min_drop_price)
    })
  }

  const columns = [
    {
      key: 'options.reason',
      label: 'Reason'
    },
    {
      key: 'options.min_drop_price',
      label: 'Min Drop Price',
      component: VxCellMoney
    },
    {
      key: 'options.rarity',
      label: 'Rarity'
    }
  ]

  return {
    formDef,
    columns
  }
}

export default useDropQuantity
