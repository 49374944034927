<template>
  <div>
    <vx-table-simple
      :columns="columns"
      :items="items"
      :loading="loading"
      stacked
    >
      <template #title>
        Task
      </template>
      <template v-if="!loading" #actions>
        <div v-if="isEdit">
          <vx-button
            :can="canUpdate"
            @click="openModal(item)"
          >
            Edit
          </vx-button>
          <vx-button
            :can="canDelete"
            :disabled="mission.is_enabled"
            :loading="deleteLoading"
            variant="danger"
            class="ml-50"
            @click="deleteTask"
          >
            Delete
          </vx-button>
        </div>
        <vx-button
          v-else
          :can="canCreate"
          variant="primary"
          @click="openModal()"
        >
          Add
        </vx-button>
      </template>
    </vx-table-simple>
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <task-editor
        :task="entity"
        :mission-id="missionId"
        @submit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import VxButton from '@/components/buttons/VxButton'
import { VxCellLink, VxCellMoney, VxTable } from '@/components/table'
import { missions, taskTypes, useResource } from '@/services/resources'
import { useModalEditor } from '@/services/modal'
import VxTableSimple from '@/components/table/vx-table-simple/VxTableSimple'
import { computed, ref } from '@vue/composition-api'
import TaskEditor from '@/views/missions/task/TaskEditor'
import { BModal } from 'bootstrap-vue'
import useVisitLink from '@/views/missions/task/useVisitLink'
import useCaseOpened from '@/views/missions/task/useCaseOpened'
import useDropSum from '@/views/missions/task/useDropSum'
import { moneyFormat } from '@/services/utils'
import useDropQuantity from '@/views/missions/task/useDropQuantity'
import useUpgrade from '@/views/missions/task/useUpgrade'

export default {
  name: 'Task',
  components: {
    TaskEditor,
    VxTableSimple,
    VxCellMoney,
    VxCellLink,
    VxButton,
    VxTable,
    BModal
  },
  props: {
    mission: {
      type: Object,
      required: true
    }
  },
  setup ({ mission }) {
    const missionId = mission.id
    // Show only first task, we can create only one task
    const items = ref([])
    const item = computed(() => items.value[0] || null)

    const { columns: visitLinkColumns } = useVisitLink()
    const { columns: caseOpenedColumns } = useCaseOpened()
    const { columns: dropSumColumns } = useDropSum(item)
    const { columns: dropsQuantityColumns } = useDropQuantity()
    const { columns: upgradeColumns } = useUpgrade()

    const optionsColumnsByType = computed(() => ({
      [taskTypes.case_opened]: caseOpenedColumns,
      [taskTypes.visit_link]: visitLinkColumns,
      [taskTypes.drops_sum]: dropSumColumns.value,
      [taskTypes.drops_quantity]: dropsQuantityColumns,
      [taskTypes.upgrade]: upgradeColumns
    }))

    const optionsColumns = computed(() => {
      return optionsColumnsByType.value[item.value?.type] || []
    })

    const columns = computed(() => [
      { key: 'id' },
      { key: 'title' },
      { key: 'type' },
      {
        key: 'value',
        formatter: (value, key, { type }) => {
          switch (type) {
            case (taskTypes.drops_sum):
              return moneyFormat(value)
            default:
              return value
          }
        }
      },
      ...optionsColumns.value
    ])

    const { can: canCreate } = useResource(missions.createTask)
    const { can: canUpdate } = useResource(missions.updateTask)
    const {
      can: canDelete,
      loading: deleteLoading,
      callRequest: deleteTaskRequest
    } = useResource(missions.deleteTask)
    const {
      can: canGetTasks,
      loading,
      callRequest: getTasksRequest
    } = useResource(missions.getAllTasks)

    const getTasks = async () => {
      if (!canGetTasks) return
      items.value = []

      const [err, res] = await getTasksRequest({ urlParams: { id: missionId } })

      if (err) return

      const { data } = res
      if (data.length) {
        items.value = [data[0]]
      }
    }

    const deleteTask = async () => {
      const [, res] = await deleteTaskRequest({
        urlParams: { missionId: missionId, taskId: item.value.id }
      })

      if (res) {
        await getTasks()
      }
    }

    getTasks()

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const isEdit = computed(() => !!item.value)
    const modalTitle = computed(() => item.value ? 'Update task' : 'Create task')

    const handleSubmit = ([, res]) => {
      if (res) {
        closeModal()
        getTasks()
      }
    }

    return {
      missionId,
      item,

      items,
      columns,
      loading,

      isEdit,
      canCreate,
      canUpdate,

      canDelete,
      deleteLoading,
      deleteTask,

      entity,
      modalTitle,
      modalIsOpen,
      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
