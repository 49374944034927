<template>
  <vx-form
    v-slot="{ loading, can }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.title"
      :rules="rules.title"
      name="title"
      label="Title"
    />

    <vx-select
      v-model="formValue.type"
      :rules="rules.type"
      :options="taskTypes"
      :clearable="false"
      name="type"
      label="Type"
    />

    <vx-input
      v-model="formValue.value"
      :rules="rules.value"
      name="value"
      label="Value"
      type="number"
      step="any"
    >
      <template #message>
        {{ valueDescription }}
      </template>
    </vx-input>

    <div v-if="currentOptions && currentOptions.component">
      <component
        :is="currentOptions.component"
        v-model="currentOptions.value"
      />
    </div>

    <vx-button
      :loading="loading"
      :can="can"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { computed, del, reactive, set, unref, watch } from '@vue/composition-api'
import { VxForm, VxInput, VxSelectResource } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { missions, passDataToResource, taskTypes } from '@/services/resources'
import VxSelect from '@/components/form/VxSelect'
import { apiDefaultValidators, setValuesToForm } from '@/services/form'
import TaskCaseOpenedFields from '@/views/missions/task/TaskCaseOpenedFields'
import TaskVisitLinkFields from '@/views/missions/task/TaskVisitLinkFields'
import useVisitLink from '@/views/missions/task/useVisitLink'
import useCaseOpened from '@/views/missions/task/useCaseOpened'
import useDropSum from '@/views/missions/task/useDropSum'
import useDropQuantity from '@/views/missions/task/useDropQuantity'
import { formatToCents, formatToDecimal } from '@/services/utils'
import useUpgrade from '@/views/missions/task/useUpgrade'

export default {
  name: 'TaskEditor',
  components: {
    VxForm,
    VxInput,
    VxSelect,
    VxSelectResource,
    VxButton,
    TaskCaseOpenedFields,
    TaskVisitLinkFields
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    missionId: {
      type: Number,
      required: true
    }
  },
  setup ({ task, missionId }, { emit }) {
    const isEdit = !!task

    const { formDef: visitLinkFormDef } = useVisitLink()
    const { formDef: caseOpenedFormDef } = useCaseOpened()
    const { formDef: dropSumFormDef } = useDropSum()
    const { formDef: dropsQuantityFormDef } = useDropQuantity()
    const { formDef: upgradeFormDef } = useUpgrade()

    const formValue = reactive({
      title: '',
      value: null,
      type: taskTypes.case_opened,
      options: null
    })

    const taskTypesWithOptions = [
      taskTypes.case_opened,
      taskTypes.drops_sum,
      taskTypes.drops_quantity,
      taskTypes.upgrade
    ]

    const optionsMap = {
      [taskTypes.case_opened]: caseOpenedFormDef,
      [taskTypes.visit_link]: visitLinkFormDef,
      [taskTypes.drops_sum]: dropSumFormDef,
      [taskTypes.drops_quantity]: dropsQuantityFormDef,
      [taskTypes.upgrade]: upgradeFormDef
    }

    const valueDescription = computed(() => {
      switch (formValue.type) {
        case (taskTypes.drops_sum):
          return 'Total cost of dropped skins ($)'
        case (taskTypes.skin_withdrawal):
          return 'Quantity of successful withdrawals'
        default:
          return 'Number of executions'
      }
    })

    const currentOptions = computed(() => optionsMap[formValue.type] || null)

    watch(
      () => formValue.type,
      (type) => {
        // TODO: rewrite after move url visit-link to options
        if (taskTypesWithOptions.includes(type)) {
          set(formValue, 'options', optionsMap[type]?.value)
          del(formValue, 'url')
        }

        if (type === taskTypes.visit_link) {
          set(formValue, 'url', optionsMap[type]?.value)
          del(formValue, 'options')
        }

        if (type === taskTypes.skin_withdrawal) {
          del(formValue, 'options')
        }
      },
      { immediate: true }
    )

    const frontToBackMapper = () => {
      // TODO: rewrite after move url visit-link to options
      const { options, value, type, url, ...rest } = formValue

      const { frontToBackMapper } = unref(currentOptions) || {}
      const payload = {
        ...rest,
        type,
        value: type === taskTypes.drops_sum ? formatToCents(value) : value
      }

      if (taskTypesWithOptions.includes(type)) {
        payload.options = frontToBackMapper ? frontToBackMapper(options) : options
      }

      if (type === taskTypes.visit_link) {
        payload.url = frontToBackMapper ? frontToBackMapper(url) : url
      }

      return payload
    }

    const backToFrontMapperMain = ({ options, value, type, ...rest }) => {
      setValuesToForm({
        ...rest,
        type,
        value: type === taskTypes.drops_sum ? formatToDecimal(value) : value
      }, formValue)
    }
    const backToFrontMapperOptions = ({ options, url, type }) => {
      // TODO: rewrite after move url visit-link to options
      if (taskTypesWithOptions.includes(type)) {
        const { backToFrontMapper } = optionsMap[type]
        const value = backToFrontMapper ? backToFrontMapper(options) : options
        setValuesToForm(value, optionsMap[type]?.value)
      }

      if (type === taskTypes.visit_link) {
        setValuesToForm({ url }, optionsMap[type]?.value)
      }
    }

    const formResource = isEdit
      ? passDataToResource(missions.updateTask, {
        frontToBackMapper,
        requestParams: { urlParams: { taskId: task.id, missionId } }
      })
      : passDataToResource(missions.createTask, {
        frontToBackMapper,
        requestParams: { urlParams: { id: missionId } }
      })

    if (isEdit) {
      backToFrontMapperMain(task)
      backToFrontMapperOptions(task)
    }

    const { maxTinyTextLength } = apiDefaultValidators
    const valueRules = {
      [taskTypes.drops_sum]: {
        min_value: 0,
        decimal: 2
      }
    }
    const rules = computed(() => (
      {
        title: {
          required: true,
          max: maxTinyTextLength
        },
        type: {
          required: true
        },
        value: {
          required: true,
          ...(valueRules[formValue.type] || { numeric: true })
        }
      }
    ))

    const handleSubmit = ([err, res]) => {
      emit('submit', [err, res])
    }

    return {
      formResource,
      formValue,
      isEdit,
      valueDescription,
      rules,
      taskTypes: Object.values(taskTypes),
      handleSubmit,
      currentOptions
    }
  }
}
</script>
