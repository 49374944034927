var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var can = ref.can;
return [_c('vx-input',{attrs:{"rules":_vm.rules.title,"name":"title","label":"Title"},model:{value:(_vm.formValue.title),callback:function ($$v) {_vm.$set(_vm.formValue, "title", $$v)},expression:"formValue.title"}}),_c('vx-input',{attrs:{"rules":_vm.rules.description,"name":"description","label":"Description"},model:{value:(_vm.formValue.description),callback:function ($$v) {_vm.$set(_vm.formValue, "description", $$v)},expression:"formValue.description"}}),_c('vx-input',{attrs:{"rules":_vm.rules.prize_type,"name":"prize_type","label":"Prize type","disabled":""},model:{value:(_vm.formValue.prize_type),callback:function ($$v) {_vm.$set(_vm.formValue, "prize_type", $$v)},expression:"formValue.prize_type"}}),_c('vx-select-resource',{attrs:{"rules":_vm.rules.prize_id,"resource":_vm.moneyBonusesResource,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"name","name":"prize_id","label":"Prize"},model:{value:(_vm.formValue.prize_id),callback:function ($$v) {_vm.$set(_vm.formValue, "prize_id", $$v)},expression:"formValue.prize_id"}}),_c('vx-select',{attrs:{"options":_vm.missionTypes,"clearable":false,"name":"type","label":"Type"},model:{value:(_vm.formValue.type),callback:function ($$v) {_vm.$set(_vm.formValue, "type", $$v)},expression:"formValue.type"}}),(_vm.missionTypeIsRegular)?_c('vx-date-range-picker',{attrs:{"rules":_vm.rules.generated_at,"clearable":false,"disabled-date":_vm.isDayBefore,"disabled-time":_vm.disabledTime,"name":"generated_at","label":"Activity datetime range","type":"datetime","show-range-duration":""},model:{value:(_vm.formValue.generated_at),callback:function ($$v) {_vm.$set(_vm.formValue, "generated_at", $$v)},expression:"formValue.generated_at"}}):_vm._e(),_c('vx-date-picker',{attrs:{"name":"created_at","label":"Created At","type":"date","disabled":""},model:{value:(_vm.formValue.created_at),callback:function ($$v) {_vm.$set(_vm.formValue, "created_at", $$v)},expression:"formValue.created_at"}}),(_vm.isEdit)?_c('vx-checkbox',{attrs:{"name":"is_enabled","label":"Enabled"},model:{value:(_vm.formValue.is_enabled),callback:function ($$v) {_vm.$set(_vm.formValue, "is_enabled", $$v)},expression:"formValue.is_enabled"}}):_vm._e(),_c('vx-button',{attrs:{"loading":loading,"can":can,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }