<template>
  <div>
    <b-alert v-if="showAlert" show variant="danger">
      Do not update the mission if it is active. This can lead to errors in the mission service.
    </b-alert>

    <b-row class="justify-content-center">
      <b-col cols="12" md="6">
        <mission-editor
          v-if="showMissionEditor"
          :mission="mission"
          @updateMission="updateMission"
        />
      </b-col>
      <b-col v-if="mission" cols="12" md="6">
        <task :tasks="mission.tasks" :mission="mission" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { missions, useResource } from '@/services/resources'
import router, { getRouteIdParam, path } from '@/router'
import { httpStatuses } from '@/services/api'
import { computed, ref, unref } from '@vue/composition-api'
import Task from '@/views/missions/task/Task'
import MissionEditor from '@/views/missions/MissionEditor'
import { BAlert, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Mission',
  components: {
    Task,
    MissionEditor,
    BRow,
    BCol,
    BAlert
  },
  setup () {
    const mission = ref(null)

    const updateMission = (updatedMission) => {
      mission.value = updatedMission
    }

    const id = getRouteIdParam()
    const isEdit = !!unref(id)

    const showMissionEditor = computed(() => {
      return isEdit ? !!mission.value : true
    })

    const showAlert = computed(() => {
      return isEdit && mission.value?.is_enabled
    })

    const getMission = async () => {
      const { callRequest } = useResource(missions.getOne)

      const [err, res] = await callRequest({ urlParams: { id: unref(id) } })

      if (err) {
        if (err.status === httpStatuses.notFound) {
          await router.push(path.notFound)
        }
      }

      if (res.data) {
        mission.value = res.data
      }
    }

    if (isEdit) {
      getMission()
    }

    return {
      mission,
      showMissionEditor,
      showAlert,
      getMission,
      updateMission
    }
  }
}
</script>
