import { reactive } from '@vue/composition-api/dist/vue-composition-api'
import TaskVisitLinkFields from '@/views/missions/task/TaskVisitLinkFields'
import { VxCellLink } from '@/components/table'

const useVisitLink = () => {
  const formValue = reactive({
    url: ''
  })

  const formDef = {
    value: formValue,
    component: TaskVisitLinkFields,
    frontToBackMapper: ({ url }) => url
  }

  const columns = [
    {
      key: 'url',
      component: VxCellLink,
      tdAttr: (url) => ({
        label: url,
        href: url
      })
    }
  ]

  return {
    formDef,
    columns
  }
}

export default useVisitLink
