<template>
  <div>
    <vx-select
      v-model="formValue.reason"
      :rules="rules.reason"
      :options="reasonOptions"
      name="reason"
      label="Reason"
    />
    <vx-input
      v-model="formValue.min_drop_price"
      :rules="rules.min_drop_price"
      type="number"
      name="min_drop_price"
      step="0.01"
      label="Min Drop Price ($)"
    />
    <vx-select-resource
      v-model="formValue.rarity"
      :resource="categoriesBaseInfoResource"
      :resource-mapper="({ rarity }) => rarity"
      :reduce="({ short }) => short"
      options-label="full"
      name="rarity"
      label="Rarity"
    />
  </div>
</template>

<script>
import { VxInput, VxSelect } from '@/components/form'
import { computed } from '@vue/composition-api'
import { categories, taskReasons } from '@/services/resources'
import VxSelectResource from '@/components/form/VxSelectResource'

export default {
  name: 'TaskDropQuantityFields',
  components: {
    VxSelectResource,
    VxSelect,
    VxInput
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const categoriesBaseInfoResource = categories.getBaseInfo

    const formValue = computed({
      get: () => props.value,
      set: (newValue) => {
        emit('input', newValue)
      }
    })

    const rules = {
      reason: {
        required: true
      },
      min_drop_price: {
        min_value: 0,
        decimal: 2
      }
    }

    return {
      formValue,
      rules,
      reasonOptions: Object.values(taskReasons),
      categoriesBaseInfoResource
    }
  }
}
</script>
