<template>
  <div>
    <vx-input
      v-model="formValue.min_case_price"
      :rules="rules.min_case_price"
      type="number"
      name="min_case_price"
      step="0.01"
      label="Min Case Price"
    />
    <vx-input
      v-model="formValue.profit_rate"
      :rules="rules.profit_rate"
      type="number"
      name="profit_rate"
      step="0.01"
      label="Profit Rate"
    />
    <vx-select-resource
      v-model="formValue.case_section_id"
      :resource="caseSectionsResource"
      :rules="rules.case_section_id"
      :reduce="({ id }) => id"
      clearable
      options-label="title"
      name="case_section_id"
      label="Case Section"
    />
  </div>
</template>

<script>
import { VxInput, VxSelectResource } from '@/components/form'
import { sections } from '@/services/resources'
import { computed } from '@vue/composition-api'

export default {
  name: 'TaskCaseOpenedFields',
  components: { VxSelectResource, VxInput },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const caseSectionsResource = sections.getAll

    const formValue = computed({
      get: () => props.value,
      set: (newValue) => emit('input', newValue)
    })

    const rules = {
      min_case_price: {
        min_value: 0,
        decimal: 2
      },
      profit_rate: {
        min_value: 0,
        decimal: 2
      }
    }

    return {
      formValue,
      rules,
      caseSectionsResource
    }
  }
}
</script>
