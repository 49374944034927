import { taskUpgradeIsWinTypes } from '@/services/resources'
import { reactive } from '@vue/composition-api'
import { isFilled } from '@/services/form'
import { VxCellBadges, VxCellMoney } from '@/components/table'
import {
  chanceFormat,
  chanceToInteger,
  integerToChance,
  formatToCents,
  formatToDecimal
} from '@/services/utils'
import TaskUpgradeFields from '@/views/missions/task/TaskUpgradeFields'

const useUpgrade = () => {
  const formValue = reactive({
    is_win: true,
    max_chance: null,
    min_item_price: null
  })

  const formDef = {
    component: TaskUpgradeFields,
    value: formValue,
    frontToBackMapper: ({ max_chance, min_item_price, ...rest }) => ({
      ...rest,
      ...(isFilled(max_chance) && { max_chance: chanceToInteger(max_chance) }),
      ...(isFilled(min_item_price) && { min_item_price: formatToCents(min_item_price) })
    }),
    backToFrontMapper: ({ min_item_price, max_chance, ...rest }) => ({
      ...rest,
      max_chance: integerToChance(max_chance),
      min_item_price: formatToDecimal(min_item_price)
    })
  }

  const columns = [
    {
      key: 'options.is_win',
      label: 'Is win',
      component: VxCellBadges,
      formatter: (isWin) => {
        return taskUpgradeIsWinTypes.find(({ value }) => value === isWin)?.label || 'Any'
      }
    },
    {
      key: 'options.max_chance',
      label: 'Max chance',
      formatter: chanceFormat
    },
    {
      key: 'options.min_item_price',
      label: 'Min item price',
      component: VxCellMoney
    }
  ]

  return {
    formDef,
    columns
  }
}

export default useUpgrade
